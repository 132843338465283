import React from "react"
import { graphql } from "gatsby"
import { MdArrowForward } from "react-icons/md"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"
import { GatsbyCloudLogo } from "../../components/shared/logos/GatsbyCloudLogo"
import GatsbyOssIcon from "../../assets/icons/gatsby-oss"
import { Heading, AnchorButton, Text, BaseAnchor } from "gatsby-interface"
import Layout from "../../layouts"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import Header from "../../components/header"
import { visuallyHiddenCss } from "../../components/shared/styles"
import PageContent from "../../layouts/PageContent"
import PageHeader from "../../layouts/PageHeader"
import {
  cloudGradientCss,
  newPricingHeaderCss,
  newPricingLedeCss,
  newPricingActionCss,
} from "./styles"
import Faqs from "./Faqs"
import {
  ZendeskFloatingWidget,
  ZendeskProvider,
} from "../../components/zendesk"
import {
  formatRichContent,
  formatNewHeaderContent,
  formatFaqContent,
} from "./helpers"
import { FormattedMessage } from "../../locales/components/FormattedMessage"
import { PlanSelector } from "../../components/billing/PlanSelector"
import {
  BillingInterval,
  SupportedPlanTier,
  supportedPlansOrder,
} from "../../components/billing/constants"
import {
  CostaCoffeeLogo,
  ButcherBoxLogo,
  ClearlinkLogo,
  DraftKingsLogo,
  HugeLogo,
} from "./CloudUsersLogos"
import PlanComparisonTable from "../../components/plan-comparison-table"
import { PlanButton } from "../../components/pricing/helpers"
import { useFlags, AnonymousFeatureFlagProvider } from "@modules/featureFlags"

import { MultiPlanSelector } from "../../dashboard/src/modules/billing/shared/components/MultiPlanSelector"
import { billing as text } from "../../locales/default.js"
import { TierFeaturesComparison } from "../../components/billing/TierFeaturesComparison"
import { PlanSelectionSummary } from "../../dashboard/src/modules/billing/shared/components/PlanSelectionSummary"
import { MachinePricingTier } from "../../dashboard/src/modules/graphql/types"
import {
  buildsFeatures,
  hostingFeatures,
} from "../../components/billing/features"
import { navigate } from "gatsby"
import { supportedTiers } from "../../dashboard/src/modules/billing/shared/constants/tiers"
import { useTracker, SegmentEventType } from "../../utils/analytics"

const multiSelectorCss = theme => ({
  marginTop: theme.space[5],
  marginBottom: theme.space[8],
})

const customPlanCss = theme => ({
  textAlign: `center`,
  color: theme.colors.grey[50],
  letterSpacing: "-0.7px",
  margin: 0,
})

const logosCss = theme => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-evenly",
  marginTop: theme.space[10],
  marginBottom: theme.space[10],
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: `58rem`,

  svg: {
    transform: `scale(0.75)`,
  },

  [theme.mediaQueries.desktop]: {
    svg: {
      transform: `scale(1)`,
    },
  },
})

const PricingPage = ({ data, location }) => {
  const { trackSegment, trackAction } = useTracker()
  const { flags, ready } = useFlags()

  const [planInfo, setPlanInfo] = React.useState({
    planId: null,
    planName: null,
    billingInterval: BillingInterval.Annual,
    buildsTier: null,
    hostingTier: null,
  })

  const [billingInterval, setBillingInterval] = React.useState(
    BillingInterval.Monthly
  )
  const { contentfulPage } = data

  const {
    pageTitle,
    pageLede,
    calloutCta,
    calloutAction,
    faqTitle,
    faqSubtitle,
    faqs,
    faqCta,
  } = React.useMemo(() => {
    const richContent = formatRichContent(contentfulPage.contentBlocks)
    const newHeaderPieces = formatNewHeaderContent(richContent)
    const faqPieces = formatFaqContent(richContent)

    return {
      ...newHeaderPieces,
      ...faqPieces,
    }
  }, [contentfulPage.contentBlocks])

  const moveToSignUp = () => {
    if (
      planInfo.buildsTier === MachinePricingTier.Free &&
      planInfo.hostingTier === MachinePricingTier.Free
    ) {
      trackAction({
        eventType: `TRACK_EVENT`,
        name: `Plan Selected`,
        valueString: `Free`,
        uiSource: `Pricing Page`,
      })
      trackSegment({
        type: SegmentEventType.Track,
        event: `Plan Selected`,
        properties: {
          location: "Pricing Page",
          plan: "Free",
        },
      })

      navigate(`/dashboard/signup`)
    } else {
      const selectedBuilds = supportedTiers[planInfo.buildsTier].key
      const selectedHosting = supportedTiers[planInfo.hostingTier].key
      const selectedPlan = `${
        supportedTiers[planInfo.buildsTier].name
      } Builds - ${supportedTiers[planInfo.hostingTier].name} Hosting`

      trackAction({
        eventType: `TRACK_EVENT`,
        name: `Plan Selected`,
        valueString: `${selectedPlan} (trial)`,
        uiSource: `Pricing Page`,
      })
      trackSegment({
        type: SegmentEventType.Track,
        event: `Plan Selected`,
        properties: {
          location: "Pricing Page",
          plan: `${selectedPlan} (trial)`,
        },
      })

      navigate(
        `/dashboard/signup?selectedBuildsTier=${selectedBuilds}&selectedHostingTier=${selectedHosting}&selectedPlan=${selectedPlan}`
      )
    }
  }

  if (!ready) {
    return null
  }

  return (
    <Layout pathname={location.pathname}>
      <Global
        /* we have to override the global body style to make sticky headers on the futures tables workd */
        styles={{
          body: {
            overflow: `visible`,
          },
        }}
      />
      <SEO contentfulPage={contentfulPage} url={location.href} />
      <Header />

      <PageContent css={{ marginTop: 0 }}>
        <PageHeader variant={`CENTER`} css={{ textAlign: `center` }}>
          <GatsbyCloudLogo />
          <PageHeader.Heading css={newPricingHeaderCss}>
            <FormattedMessage
              message={pageTitle}
              tags={{
                strong: content => (
                  <span css={cloudGradientCss}>{content}</span>
                ),
              }}
            />
          </PageHeader.Heading>
          <PageHeader.Lede css={newPricingLedeCss}>{pageLede}</PageHeader.Lede>

          {!flags.multiTiersPlans && (
            <PageHeader.Action>
              <div css={newPricingActionCss}>
                <GatsbyOssIcon />
                <span css={theme => ({ padding: `0 ${theme.space[4]}` })}>
                  <FormattedMessage
                    message={calloutCta}
                    tags={{
                      strong: content => <strong>&nbsp;{content}</strong>,
                    }}
                  />
                </span>
                <AnchorButton
                  href={calloutAction?.to}
                  variant={`SECONDARY`}
                  rightIcon={<MdArrowForward />}
                >
                  {calloutAction?.label}
                </AnchorButton>
              </div>
            </PageHeader.Action>
          )}
        </PageHeader>
        <PageContent.Positioner>
          {flags.multiTiersPlans ? (
            <MultiPlanSelector
              planInfo={planInfo}
              setPlanInfo={setPlanInfo}
              contactSalesLink="/contact-us"
              css={multiSelectorCss}
              showDetailsLinks={true}
            />
          ) : (
            <PlanSelector
              planOptions={supportedPlansOrder}
              onSelectPlan={() => undefined}
              selectedInterval={billingInterval}
              onSelectInterval={setBillingInterval}
              getPlanHint={tier => {
                if (
                  tier === SupportedPlanTier.Individual ||
                  tier === SupportedPlanTier.Team
                ) {
                  return "No credit card required"
                }
              }}
              renderAction={tier => <PlanButton tier={tier} />}
            />
          )}

          <Text size="L" css={customPlanCss}>
            <FormattedMessage
              message={text.messages.contactSalesForCustomPlan}
              tags={{
                link: content => (
                  <BaseAnchor href="mailto:sales@gatsbyjs.com">
                    {content}
                  </BaseAnchor>
                ),
              }}
            />
          </Text>

          <Heading as="h2" css={visuallyHiddenCss}>
            Gatsby Cloud customers
          </Heading>
          <div css={logosCss}>
            <CostaCoffeeLogo />
            <ButcherBoxLogo />
            <ClearlinkLogo />
            <DraftKingsLogo />
            <HugeLogo />
          </div>

          {flags.multiTiersPlans ? (
            <React.Fragment>
              <TierFeaturesComparison
                tierType="builds"
                features={buildsFeatures}
                note={
                  <React.Fragment>
                    <sup>*</sup> Build speed is currently determined by allotted
                    cores and virtual machine types to optimize time to build.
                  </React.Fragment>
                }
              />
              <TierFeaturesComparison
                tierType="hosting"
                features={hostingFeatures}
              />

              <PlanSelectionSummary
                planInfo={planInfo}
                onNext={moveToSignUp}
                helpWidget={
                  <ZendeskProvider>
                    <ZendeskFloatingWidget inline={true} />
                  </ZendeskProvider>
                }
              />
            </React.Fragment>
          ) : (
            <PlanComparisonTable />
          )}
        </PageContent.Positioner>

        {faqs && (
          <Faqs
            faqs={faqs}
            title={faqTitle}
            subtitle={faqSubtitle}
            cta={faqCta}
            titleAs="h2"
            subtitleAs="h3"
          />
        )}
      </PageContent>
      <Footer />
      {!flags.multiTiersPlans && (
        <aside>
          <ZendeskProvider>
            <ZendeskFloatingWidget />
          </ZendeskProvider>
        </aside>
      )}
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

const PricingPageWithFlags = props => (
  <AnonymousFeatureFlagProvider>
    <PricingPage {...props} />
  </AnonymousFeatureFlagProvider>
)

export default PricingPageWithFlags

export const pageQuery = graphql`
  query ContentfulPricingPage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      name
      title
      subtitle {
        subtitle
      }
      plans {
        name
        intro {
          childMarkdownRemark {
            html
          }
        }
        details {
          id
          tip {
            id
            content {
              id
              childMarkdownRemark {
                id
                html
              }
            }
          }
          text {
            id
            childMarkdownRemark {
              id
              html
            }
          }
        }
        ctaLabel
      }
      quickLinks {
        displayTitle
        url
      }
      sentenceLinks {
        sentence
        anchorText
        url
      }
      contentBlocks {
        __typename
        ... on ContentfulRichContentBlock {
          contentfulid
          title
          subtitle
          badge
          actions {
            displayTitle
            url
          }
          content {
            __typename
            ... on ContentfulMarkdownContent {
              body {
                childMarkdownRemark {
                  html
                }
                internal {
                  content
                }
              }
              contentfulid
            }
            ... on ContentfulPlan {
              id
              name
              intro {
                childMarkdownRemark {
                  html
                }
              }
              details {
                id
                tip {
                  id
                  content {
                    id
                    childMarkdownRemark {
                      id
                      html
                    }
                  }
                }
                text {
                  id
                  childMarkdownRemark {
                    id
                    html
                  }
                }
              }
              ctaLabel
            }
            ... on ContentfulFaq {
              id
              question
              category
              answer {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`
