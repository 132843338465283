import * as React from "react"
import { MdCheck } from "react-icons/md"
import {
  FeaturesIcon,
  CollaborationIcon,
  LoginIcon,
  ProviderIcon,
  DeployIcon,
} from "gatsby-interface"
import { visuallyHiddenCss } from "./shared/styles/a11y"
import {
  supportedPlans,
  supportedPlansOrder,
  planFeatures,
} from "./billing/constants"
import { getColorsForPlanTier } from "./billing/helpers"
import { interpolateMessage } from "../locales/utils"
import {
  planInformation as planInformationText,
  billing as billingText,
} from "../locales/default.js"

import { PlanButton } from "./pricing/helpers"

const IconByFeature = {
  Features: FeaturesIcon,
  Collaboration: CollaborationIcon,
  Login: LoginIcon,
  Provider: ProviderIcon,
  Deploy: DeployIcon,
}

const wideTableCss = theme => ({
  display: `none`,
  width: `100%`,
  marginTop: theme.space[8],
  borderRadius: theme.radii[3],
  boxShadow: theme.shadows.dialog,
  textAlign: `center`,
  tableLayout: `fixed`,
  [theme.mediaQueries.desktop]: {
    display: `table`,
  },
})

const narrowTableCss = theme => ({
  display: `table`,
  width: `100%`,
  marginTop: theme.space[8],
  borderRadius: theme.radii[3],
  boxShadow: theme.shadows.dialog,
  textAlign: `center`,
  tableLayout: `fixed`,
  [theme.mediaQueries.desktop]: {
    display: `none`,
  },
})

const planHeaderCss = theme => ({
  border: `none`,
  borderLeft: `1px solid ${theme.colors.grey[20]}`,
  padding: theme.space[6],
  ":last-child": {
    paddingRight: theme.space[6],
    paddingLeft: theme.space[6],
  },

  textAlign: `center`,
  width: `20%`,
})

const planHeaderTitleCss = theme => ({
  color: theme.colors.blue[80],
  fontSize: theme.fontSizes[4],
  marginBottom: theme.space[2],
})

const planColorCss = (theme, planTier) => ({
  color: getColorsForPlanTier(planTier)(theme).primaryColor,
})

const planPricingCss = theme => ({
  fontSize: theme.fontSizes[2],
  marginBottom: theme.space[4],
  fontWeight: theme.fontWeights.body,
})

const categoryRowHeaderCss = theme => ({
  background: theme.colors.grey[20],
  borderWidth: "0",
  borderStyle: "solid",
  borderColor: `transparent`,
  fontSize: theme.fontSizes[3],
  textAlign: `center`,
  [theme.mediaQueries.desktop]: {
    textAlign: `left`,
    "&&": {
      paddingLeft: theme.space[6],
    },
  },
})

const itemRowCss = _theme => ({
  border: `none`,
})

const itemRowHeaderCss = theme => ({
  border: `none`,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semiBold,
  textAlign: `center`,
  [theme.mediaQueries.desktop]: {
    textAlign: `left`,
    "&&": {
      paddingLeft: theme.space[6],
    },
  },
})

const tableCellCss = theme => ({
  textAlign: `center`,
  border: `none`,
  borderBottom: `1px solid ${theme.colors.grey[20]}`,
  paddingTop: 0,
  [theme.mediaQueries.desktop]: {
    border: `none`,
    borderLeft: `1px solid ${theme.colors.grey[20]}`,
    paddingTop: theme.space[4],
  },
})

const categoryTitleCss = theme => ({
  height: `32px`,
  paddingLeft: theme.space[2],
  verticalAlign: `middle`,
})

const checkedIconCss = theme => ({
  color: theme.colors.green[70],
})

const CheckedIcon = ({ label = "Included" }) => (
  <MdCheck css={checkedIconCss} aria-label={label} />
)

const getTierHeaderElement = ({ tier, display = "wide" }) => {
  const planInformation = supportedPlans[tier]
  const amount = planInformation?.monthlyAmount?.MONTHLY
  const roundedAmountInUSD = amount !== null ? Math.round(amount / 100) : null
  return (
    <th
      key={`${planInformation.name}_header`}
      id={`${display}-${tier}`}
      scope="col"
      css={planHeaderCss}
    >
      <p
        css={theme => [
          planHeaderTitleCss(theme),
          planColorCss(theme, planInformation.name),
        ]}
      >
        {planInformation.name}
      </p>

      {amount !== null ? (
        <p
          css={planPricingCss}
          aria-label={
            roundedAmountInUSD !== null
              ? interpolateMessage(
                  planInformationText.messages.monthlyAmountAria,
                  { amount: roundedAmountInUSD }
                )
              : undefined
          }
        >
          <span>$</span>
          <span>{roundedAmountInUSD}</span>
          <span>
            {" / "}
            {billingText.morphemes.month}
          </span>
        </p>
      ) : (
        <p css={planPricingCss}>{planInformationText.messages.custom}</p>
      )}
      <PlanButton
        tier={tier}
        css={theme => ({
          fontSize: theme.fontSizes[1],
        })}
      />
    </th>
  )
}

const getCategoryHeaderElement = ({
  categoryTitle,
  categoryId,
  display = "wide",
  tier,
}) => {
  const IconComponent = IconByFeature[categoryId]
  const cellContent = (
    <div>
      <IconComponent size="medium" />
      <span css={categoryTitleCss}>{categoryTitle}</span>
    </div>
  )
  const categoryHeaderId =
    display === "wide" ? `wide-${categoryId}` : `narrow-${tier}-${categoryId}`

  return display === "wide" ? (
    <th
      css={categoryRowHeaderCss}
      id={categoryHeaderId}
      className="span"
      colSpan="5"
      scope="colgroup"
    >
      {cellContent}
    </th>
  ) : (
    <tr>
      <th css={categoryRowHeaderCss} id={categoryHeaderId}>
        {cellContent}
      </th>
    </tr>
  )
}

const getItemHeaderElement = ({ categoryId, item, tier, display = "wide" }) => {
  const categoryHeaderId =
    display === `wide`
      ? `${display}-${categoryId}`
      : `${display}-${tier}-${categoryId}`

  const itemHeaderId =
    display === `wide`
      ? `${display}-${item.id}`
      : `${display}-${tier}-${item.id}`
  return (
    <th css={itemRowHeaderCss} headers={categoryHeaderId} id={itemHeaderId}>
      {item.title}
    </th>
  )
}

const getItemCellElement = ({ item, tier, categoryId, display = "wide" }) => {
  const itemHeaderId =
    display === `wide`
      ? `${display}-${item.id}`
      : `${display}-${tier}-${item.id}`

  const categoryHeaderId =
    display === `wide`
      ? `${display}-${categoryId}`
      : `${display}-${tier}-${categoryId}`
  return (
    <td
      key={`${display}-${tier}-${item.id}-item-detail`}
      css={tableCellCss}
      headers={`${display}-${tier} ${categoryHeaderId} ${itemHeaderId}`}
    >
      {typeof item[tier] === "boolean" ? (
        item[tier] ? (
          <CheckedIcon />
        ) : (
          <span css={visuallyHiddenCss}>Not included</span>
        )
      ) : (
        item[tier]
      )}
    </td>
  )
}

function PlanComparisonTable() {
  return (
    <>
      {/* Wide view table */}
      <table
        css={wideTableCss}
        summary={planInformationText.featureComparisonSummary}
      >
        <thead>
          <tr>
            <th css={planHeaderCss}></th>
            {supportedPlansOrder.map(tier =>
              getTierHeaderElement({
                tier,
                display: `wide`,
              })
            )}
          </tr>
        </thead>
        <tbody>
          {planFeatures.map(category => (
            <React.Fragment key={category.id}>
              <tr>
                {getCategoryHeaderElement({
                  categoryTitle: category.title,
                  categoryId: category.id,
                })}
              </tr>
              {category.items.map(item => {
                return (
                  <tr key={`wide-${item.id}-item-header-row`} css={itemRowCss}>
                    {getItemHeaderElement({
                      item,
                      categoryId: category.id,
                      display: `wide`,
                    })}
                    {supportedPlansOrder.map(tier =>
                      getItemCellElement({
                        item,
                        tier,
                        categoryId: category.id,
                        display: `wide`,
                      })
                    )}
                  </tr>
                )
              })}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Narrow view tables */}
      {supportedPlansOrder.map(tier => {
        return (
          <table key={`narrow-${tier}`} css={narrowTableCss}>
            <thead>
              <tr>
                {getTierHeaderElement({
                  tier,
                  display: `narrow`,
                })}
              </tr>
            </thead>
            <tbody>
              {planFeatures.map(category => (
                <React.Fragment key={category.id}>
                  {getCategoryHeaderElement({
                    categoryTitle: category.title,
                    categoryId: category.id,
                    display: `narrow`,
                    tier,
                  })}
                  {category.items.map(item => {
                    if (!item[tier]) {
                      return null
                    }
                    return (
                      <React.Fragment
                        key={`narrow-${tier}-${item.id}-item-header-row`}
                      >
                        <tr css={itemRowCss}>
                          {getItemHeaderElement({
                            item,
                            categoryId: category.id,
                            display: `narrow`,
                            tier,
                          })}
                        </tr>
                        <tr css={itemRowCss}>
                          {getItemCellElement({
                            item,
                            categoryId: category.id,
                            tier,
                            display: `narrow`,
                          })}
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )
      })}
    </>
  )
}

export default PlanComparisonTable
