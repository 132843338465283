import * as React from "react"
import {
  Text,
  FreePlanIcon,
  IndividualPlanIcon,
  TeamPlanIcon,
  EnterprisePlanIcon,
} from "gatsby-interface"
import { MdAdd } from "react-icons/md"
import {
  billing as billingText,
  planInformation as planInformationText,
} from "../../locales/default.js"
import { interpolateMessage } from "../../locales/utils"
import { getColorsForPlanTier } from "./helpers"

const BORDER_WIDTH = `4px`

const baseCss = theme => ({
  background: theme.colors.white,
  borderRadius: theme.radii[3],
  boxShadow: theme.shadows.dialog,
  padding: BORDER_WIDTH,
})

const innerCss = theme => ({
  background: theme.colors.white,
  borderRadius: `inherit`,
  height: `100%`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  padding: theme.space[7],
  textAlign: `center`,
})

const selectedCss = _theme => ({
  backgroundSize: `auto`,
})

const IconByTier = {
  Free: FreePlanIcon,
  Individual: IndividualPlanIcon,
  Team: TeamPlanIcon,
  Enterprise: EnterprisePlanIcon,
}

const titleCss = theme => ({
  fontSize: theme.fontSizes[6],
  lineHeight: theme.lineHeights.heading,
  fontWeight: theme.fontWeights.bold,
  letterSpacing: `-0.01em`,
  marginBottom: theme.space[5],
})

const MIN_DESCRIPTION_LINES = 2

const descriptionCss = theme => ({
  color: theme.colors.grey[70],
  marginTop: 0,
  marginBottom: theme.space[7],
  fontSize: theme.fontSizes[1],
  lineHeight: theme.lineHeights.default,
  minHeight: `calc(${MIN_DESCRIPTION_LINES} * ${theme.fontSizes[1]} * ${theme.lineHeights.default})`,
})

const pricingCss = theme => ({
  color: theme.colors.grey[60],
  marginBottom: theme.space[7],
  fontSize: theme.fontSizes[2],
  lineHeight: theme.lineHeights.default,
})

const dollarSignCss = _theme => ({
  display: `inline-block`,
  transform: `translateY(-40%)`,
})

const amountCss = theme => ({
  fontSize: theme.fontSizes[8],
  letterSpacing: `-0.25px`,
  color: theme.colors.grey[90],
  fontWeight: theme.fontWeights.bold,
  fontFamily: theme.fonts.heading,
})

const featuresList = theme => ({
  listStyle: `none`,
  padding: 0,
  marginTop: 0,
  marginBottom: theme.space[7],
  marginLeft: 0,
  marginRight: 0,
  fontSize: theme.fontSizes[2],
  lineHeight: theme.lineHeights.default,
  color: theme.colors.grey[90],
  textAlign: `start`,
  alignSelf: `stretch`,
  flexGrow: 1,
})

const featureItemCss = theme => ({
  marginBottom: theme.space[3],
})

const featureIconCss = theme => ({
  marginRight: theme.space[3],
  fontSize: theme.fontSizes[3],
  verticalAlign: `middle`,
})

const hintCss = theme => ({
  textAlign: `start`,
  marginTop: "auto",
  marginBottom: theme.space[4],
  color: theme.colors.grey[70],
})

export function PlanInformationCard({
  tier,
  title,
  description,
  amount = null,
  featuresIntro,
  features,
  hint,
  action,
  selected,
}) {
  const planColors = getColorsForPlanTier(tier, false)
  const colorCss = theme => ({
    color: planColors(theme).primaryColor,
  })

  const finalBaseCss = theme => [
    baseCss(theme),
    {
      background: planColors(theme).gradient,
      // These styles MUST follow "background" in order to work
      backgroundRepeat: `no-repeat`,
      backgroundSize: `100% ${BORDER_WIDTH}`,
    },
    selected && selectedCss(theme),
  ]

  const finalTitleCss = theme => [titleCss(theme), colorCss(theme)]

  const finalIconCss = theme => [featureIconCss(theme), colorCss(theme)]

  const IconComponent = IconByTier[tier]
  const roundedAmountInUSD = amount !== null ? Math.round(amount / 100) : null

  return (
    <div css={finalBaseCss}>
      <div css={innerCss}>
        <div>
          <IconComponent size="large" />
        </div>
        <div css={finalTitleCss}>{title}</div>
        {description && (
          <Text size="S" css={descriptionCss}>
            {description}
          </Text>
        )}
        {amount !== null && (
          <div
            css={pricingCss}
            aria-label={
              roundedAmountInUSD !== null
                ? interpolateMessage(
                    planInformationText.messages.monthlyAmountAria,
                    { amount: roundedAmountInUSD }
                  )
                : undefined
            }
          >
            <span css={dollarSignCss}>$</span>
            <span css={amountCss}>{roundedAmountInUSD}</span>
            <span>
              {" / "}
              {billingText.morphemes.month}
            </span>
          </div>
        )}
        <ul css={featuresList}>
          {featuresIntro && (
            <li key="intro" css={featureItemCss}>
              <Text variant="EMPHASIZED" css={{ margin: 0, color: `inherit` }}>
                {featuresIntro}
              </Text>
            </li>
          )}
          {features.map((feature, idx) => {
            return (
              <li key={idx} css={featureItemCss}>
                <MdAdd css={finalIconCss} aria-hidden />
                {feature}
              </li>
            )
          })}
        </ul>
        {hint && (
          <Text css={hintCss} size="S">
            {hint}
          </Text>
        )}
        <div css={{ marginTop: `auto`, alignSelf: `stretch` }}>{action}</div>
      </div>
    </div>
  )
}
