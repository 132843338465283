import * as React from "react"
import { FeaturesIcon, LoginIcon } from "gatsby-interface"

export const buildsFeatures = [
  {
    category: `Builds`,
    categoryIcon: FeaturesIcon,
    items: [
      {
        name: (
          <React.Fragment>
            Build Speed<sup>*</sup>
          </React.Fragment>
        ),
        id: `buildSpeed`,
        values: [`Default`, `Default`, `Premium`, `Dedicated`],
      },
      {
        name: `Available Cores`,
        id: `availableCores`,
        values: [1, 2.5, 4, 8],
      },
      {
        name: `Available Memory`,
        id: `availableMemory`,
        values: [`1GB`, `4GB`, `8GB`, `8GB+`],
      },
      {
        name: `Deploy Previews`,
        id: `pullMerge`,
        values: [true, true, true, true],
      },
      {
        name: `CMS Preview`,
        id: `cmsPreview`,
        values: [true, true, true, true],
      },
      {
        name: `Monthly Builds`,
        id: `monthlyBuilds`,
        values: ["500", "Unlimited", "Unlimited", "Unlimited"],
      },
      {
        name: `Lighthouse Reports`,
        id: `lighthouseReports`,
        values: [true, true, true, true],
      },
      {
        name: `Incremental Builds`,
        id: `incrementalBuilds`,
        values: [true, true, true, true],
      },
      {
        name: `Cloud Builds`,
        id: `cloudBuilds`,
        values: [false, true, true, true],
      },
      {
        name: `Password Protected Builds`,
        id: `passProtected`,
        values: [false, true, true, true],
      },
      {
        name: `Users`,
        id: `users`,
        values: [1, 2, 10, `Unlimited`],
      },
    ],
  },
  {
    category: `Git Providers`,
    categoryIcon: LoginIcon,
    items: [
      {
        name: `Github`,
        id: `github`,
        values: [true, true, true, true],
      },
      {
        name: `GitLab`,
        id: `gitlab`,
        values: [true, true, true, true],
      },
      {
        name: `BitBucket`,
        id: `bitbucket`,
        values: [true, true, true, true],
      },
    ],
  },
]

export const hostingFeatures = [
  {
    items: [
      {
        name: `Production Websites`,
        id: `prodWebsites`,
        values: [`1`, `Up to 10`, `Up to 25`, `Custom`],
      },
      {
        name: `Bandwidth`,
        id: `bandwidth`,
        values: [`100GB`, `300GB`, `1TB`, `Custom`],
      },
      {
        name: `Requests`,
        id: `requests`,
        values: [`5M`, `30M`, `80M`, `Custom`],
      },
      {
        name: "Serverless Functions",
        id: `serverlessFunctions`,
        values: [
          `25K Invocations`,
          `125K Invocations`,
          `250K Invocations`,
          `Custom`,
        ],
      },
      {
        name: `Rollbacks`,
        id: `rollbacks`,
        values: [false, false, true, true],
      },
      {
        name: `Custom Domains`,
        id: `domains`,
        values: [true, true, true, true],
      },
      {
        name: `Free SSL`,
        id: `ssl`,
        values: [true, true, true, true],
      },
      {
        name: `Edge CDN`,
        id: `edge-cdn`,
        values: [true, true, true, true],
      },
    ],
  },
]
